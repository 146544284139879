<template>
    <button type="button" class="scrollerUp" @click="scrollUp">
      <svg class="scrollerUp__arrowIcon" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9733 5.90768L6.03236 1.98488L1.0909 5.90768C-0.0960388 6.35606 -0.341174 5.06767 0.487461 4.26678L5.73064 0.104406C5.90653 -0.0348021 6.15868 -0.0348021 6.33456 0.104406L11.5777 4.26678C12.4059 4.81 11.9156 6.35606 10.9733 5.90768Z" fill="#353535"/>
        <path d="M5.25 2.75C5.25 2.33579 5.58579 2 6 2C6.41421 2 6.75 2.33579 6.75 2.75V15.25C6.75 15.6642 6.41421 16 6 16C5.58579 16 5.25 15.6642 5.25 15.25V2.75Z" fill="#353535"/>
      </svg>
    </button>
</template>

<script>
export default {
  name: 'scroll-up-button',
  methods: {
    scrollUp () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
.scrollerUp {
  --pointer-color: var(--main-text-color);

  position: sticky;
  bottom: 2vw;
  left: calc(100vw + 5vw - 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F2;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -2.5vw;
  height: calc(2.5em + 0.25vw);
  width: calc(2.5em + 0.25vw);
  transition: all 300ms;
}

@media screen and (max-width: 600px) {
  .scrollerUp {
    left: 85.5vw;
    height: 10.5vw;
    width: 10.5vw;
  }
}

.scrollerUp__arrowIcon {
  height: calc(0.75em + 0.5vw);
  transition: all 300ms;
}

@media screen and (max-width: 600px) {
  .scrollerUp__arrowIcon {
    height: 3.75vw;
    width: 3.75vw;
  }
}

.scrollerUp:hover {
  --pointer-color: #fff;

  background: var(--main-text-color);
}

.scrollerUp:hover .scrollerUp__arrowIcon path {
  fill: #fff;
}
</style>
