<template>
    <nav class="navPanel">
        <a href="https://gyanthub.com/" class="logoLink">
            <svg width="143" height="50" viewBox="0 0 143 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoLink__logo">
                <path d="M63.4505 34V27.7865L55.8953 20.1674H60.4475L65.3832 25.3267L70.3827 20.1674H74.344L66.8207 27.7226V34H63.4505ZM74.8432 34L83.0533 20.1674H86.2958L94.6337 34H90.8481L89.1071 30.9651H79.9546L78.2135 34H74.8432ZM81.3921 28.4414H87.7334L84.5069 22.6752L81.3921 28.4414ZM99.222 34V20.1674H101.538L113.278 29.3519V20.1674H116.329V34H113.997L102.289 24.6718V34H99.222ZM131.875 22.6432V34H128.504V22.6432H121.828V20.1674H138.567V22.6432H131.875Z" fill="#232323"/>
                <path d="M1.16831 38.4272V1.16831H37.496C43.2423 1.16831 47.9005 5.82657 47.9005 11.5728V38.4272C47.9005 44.1734 43.2423 48.8317 37.496 48.8317H11.5728C5.82658 48.8317 1.16831 44.1734 1.16831 38.4272Z" stroke="#232323" strokeWidth="2.33661"/>
                <path d="M37.6878 36.7406C36.7156 37.2592 35.2682 37.4969 33.367 37.4969H15.6952C13.2756 37.4969 11.6121 37.0863 10.7048 36.2868C9.79743 35.4872 9.34375 33.9098 9.34375 31.576V18.3944C9.34375 16.0606 9.79743 14.5047 10.7048 13.6836L15.2848 17.6597V32.7861H33.043L37.6878 36.7406Z" fill="#232323"/>
                <path d="M33.9013 32.0818V27.2414H24.1797V22.9844H39.7127V31.6064C39.7127 33.7241 39.3455 35.2152 38.5893 36.0795L33.9013 32.0818Z" fill="#232323"/>
                <path d="M11.6602 13.1267C12.6107 12.7161 13.9717 12.5 15.7 12.5H33.3718C35.9211 12.5 37.6062 12.9322 38.4487 13.7749C39.2912 14.6393 39.7233 16.2816 39.7233 18.745L33.9119 19.7607V16.9515H16.1753L11.6602 13.1267Z" fill="#232323"/>
            </svg>
        </a>
        <button type="button" class="logoMenuButton" @click="toggleMenu()">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="logoMenuButton__logo">
                <path d="M1.16831 38.4272V1.16831H37.496C43.2423 1.16831 47.9005 5.82657 47.9005 11.5728V38.4272C47.9005 44.1734 43.2423 48.8317 37.496 48.8317H11.5728C5.82658 48.8317 1.16831 44.1734 1.16831 38.4272Z" stroke="#232323" strokeWidth="2.33661"/>
                <path d="M37.6878 36.7406C36.7156 37.2592 35.2682 37.4969 33.367 37.4969H15.6952C13.2756 37.4969 11.6121 37.0863 10.7048 36.2868C9.79743 35.4872 9.34375 33.9098 9.34375 31.576V18.3944C9.34375 16.0606 9.79743 14.5047 10.7048 13.6836L15.2848 17.6597V32.7861H33.043L37.6878 36.7406Z" fill="#232323"/>
                <path d="M33.9013 32.0818V27.2414H24.1797V22.9844H39.7127V31.6064C39.7127 33.7241 39.3455 35.2152 38.5893 36.0795L33.9013 32.0818Z" fill="#232323"/>
                <path d="M11.6602 13.1267C12.6107 12.7161 13.9717 12.5 15.7 12.5H33.3718C35.9211 12.5 37.6062 12.9322 38.4487 13.7749C39.2912 14.6393 39.7233 16.2816 39.7233 18.745L33.9119 19.7607V16.9515H16.1753L11.6602 13.1267Z" fill="#232323"/>
            </svg>
        </button>
        <div class="menuBox" :class="{menuBox_opened: isMenuOpen}" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
            <div class="menuHeader">
                <svg width="143" height="50" viewBox="0 0 143 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="menuHeader__logo">
                    <path d="M63.4505 34V27.7865L55.8953 20.1674H60.4475L65.3832 25.3267L70.3827 20.1674H74.344L66.8207 27.7226V34H63.4505ZM74.8432 34L83.0533 20.1674H86.2958L94.6337 34H90.8481L89.1071 30.9651H79.9546L78.2135 34H74.8432ZM81.3921 28.4414H87.7334L84.5069 22.6752L81.3921 28.4414ZM99.222 34V20.1674H101.538L113.278 29.3519V20.1674H116.329V34H113.997L102.289 24.6718V34H99.222ZM131.875 22.6432V34H128.504V22.6432H121.828V20.1674H138.567V22.6432H131.875Z" fill="#232323"/>
                    <path d="M1.16831 38.4272V1.16831H37.496C43.2423 1.16831 47.9005 5.82657 47.9005 11.5728V38.4272C47.9005 44.1734 43.2423 48.8317 37.496 48.8317H11.5728C5.82658 48.8317 1.16831 44.1734 1.16831 38.4272Z" stroke="#232323" strokeWidth="2.33661"/>
                    <path d="M37.6878 36.7406C36.7156 37.2592 35.2682 37.4969 33.367 37.4969H15.6952C13.2756 37.4969 11.6121 37.0863 10.7048 36.2868C9.79743 35.4872 9.34375 33.9098 9.34375 31.576V18.3944C9.34375 16.0606 9.79743 14.5047 10.7048 13.6836L15.2848 17.6597V32.7861H33.043L37.6878 36.7406Z" fill="#232323"/>
                    <path d="M33.9013 32.0818V27.2414H24.1797V22.9844H39.7127V31.6064C39.7127 33.7241 39.3455 35.2152 38.5893 36.0795L33.9013 32.0818Z" fill="#232323"/>
                    <path d="M11.6602 13.1267C12.6107 12.7161 13.9717 12.5 15.7 12.5H33.3718C35.9211 12.5 37.6062 12.9322 38.4487 13.7749C39.2912 14.6393 39.7233 16.2816 39.7233 18.745L33.9119 19.7607V16.9515H16.1753L11.6602 13.1267Z" fill="#232323"/>
                </svg>
                <!-- <button type="button" class="menuHeader__languageSwitcher">
                    EN
                </button> -->
            </div>

            <ul class="menu">
                <li class="menu__item">
                    <a href="https://gyanthub.com/"
                    class="menu__link"
                    :class="{ menu__link_active: parentPage === 'home' }">
                        Home
                </a>
                </li>
                <li class="menu__item">
                    <a href="https://gyanthub.com/tech-solutions/"
                        class="menu__link"
                        :class="{ menu__link_active: parentPage === 'tech-solutions' }">
                        Tech Solutions
                </a>
                </li>
                <li class="menu__item">
                    <a href="https://gyanthub.com/consulting-and-engineering/"
                        class="menu__link"
                        :class="{ menu__link_active: parentPage === 'consulting-and-engineering' }">
                        Consulting & Engineering
                </a>
                </li>
                <!-- <li class="menu__item">
                    <Link to="/whats-new/"
                        class="menu__link"
                        partiallyActive
                        activeclass="navBarMenuItem__link_active">
                        What's New!
                    </Link>
                </li> -->
                <li class="menu__item">
                    <a href="https://gyanthub.com/faq/"
                        class="menu__link"
                        partiallyActive
                        :class="{ menu__link_active: parentPage === 'faq' }">
                        FAQ
                </a>
                </li>
                <li class="menu__item">
                    <a href="https://gyanthub.com/contact-us/"
                        class="menu__link"
                        :class="{ menu__link_active: parentPage === 'contact-us' }">
                        Contact Us
                </a>
                </li>
            </ul>
            <div class="actions">
                <!-- <button type="button"
                    class="menu__button">
                    EN
                </button> -->
                <!-- desktop version -->
                <a href="https://gyanthub.com/login/" class="actions__loginLink">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu__loginIcon">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.9987 15.2181C10.9154 15.2181 11.6654 14.4681 11.6654 13.5514C11.6654 12.6348 10.9154 11.8848 9.9987 11.8848C9.08203 11.8848 8.33203 12.6348 8.33203 13.5514C8.33203 14.4681 9.08203 15.2181 9.9987 15.2181ZM14.9987 7.7181H14.1654V6.05143C14.1654 3.75143 12.2987 1.88477 9.9987 1.88477C7.6987 1.88477 5.83203 3.75143 5.83203 6.05143V7.7181H4.9987C4.08203 7.7181 3.33203 8.4681 3.33203 9.38477V17.7181C3.33203 18.6348 4.08203 19.3848 4.9987 19.3848H14.9987C15.9154 19.3848 16.6654 18.6348 16.6654 17.7181V9.38477C16.6654 8.4681 15.9154 7.7181 14.9987 7.7181ZM7.41509 6.05208C7.41509 4.62708 8.57342 3.46875 9.99842 3.46875C11.4234 3.46875 12.5818 4.62708 12.5818 6.05208V7.71875H7.41509V6.05208ZM14.9973 17.7181H4.99733V9.38477H14.9973V17.7181Z" fill="#232323"/>
                    </svg>
                    Log In
                </a>
                <!-- mobilev version -->
                <a href="https://gyanthub.com/login/" class="button button_solid button_dark actions__loginButton">
                    Log In
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'nav-bar',
  props: {
    parentPage: String
  },
  data () {
    return {
      xDown: null,
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleTouchStart (e) {
      this.xDown = e.touches[0].clientX;
    },
    handleTouchMove (e) {
      if (!this.xDown) {
        return;
      }

      const xUp = e.touches[0].clientX;
      const xDiff = this.xDown - xUp;

      if (xDiff > 0 && this.isMenuOpen) {
        // Swipe from right to left while the menu is open, close the menu
        this.isMenuOpen = false;
      }

      this.xDown = null;
    }
  }
};
</script>

<style scoped>
.navPanel {
    position: fixed;
    top: 0;
    display: flex;
    align-items: stretch;
    background-color: #fff;
    box-sizing: border-box;
    font-size: 0.885em;
    height: clamp(80px, 6.25vw, 100px);
    letter-spacing: 0.156765px;
    padding: 0 clamp(calc((100vw - 1400px) / 2), 7.75vw, 500px) 0 clamp(calc((100vw - 1400px) / 2), 6.75vw, 500px);
    width: 100%;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .navPanel {
        align-items: center;
        height: 20vw;
        font-size: 1em;
        min-height: 60px;
        padding: 0 3vw;
    }
}

.logoLink {
    align-self: center;
}

@media screen and (max-width: 600px) {
    .logoLink {
        display: none;
    }
}

.logoLink__logo {
    height: clamp(40px, 3.15vw, 50px);
}

@media screen and (max-width: 600px) {
    .logoLink__logo {
        height: 9.25vw;
    }
}

.logoLink .logoLink__logo path {
    transition: 300ms all;
}

.logoLink:hover .logoLink__logo path:not(:nth-child(2)) {
    fill: var(--main-color);
}

.logoLink:hover .logoLink__logo path:nth-child(2) {
    stroke: var(--main-color);
}

/* .navPanel_dark .logoLink__logo path:not(:nth-child(2)) {
    fill: #fff;
}

.navPanel_dark .logoLink__logo path:nth-child(2) {
    stroke: #fff;
} */

.logoMenuButton {
    background-color: transparent;
    border: none;
    display: none;
}

@media screen and (max-width: 600px) {
    .logoMenuButton {
        display: initial;
    }
}

.logoMenuButton__logo {
    height: 9vw;
    margin-right: 2vw;
}

/* .logoMenuButton__logo_light path:first-child {
    stroke: #fff;
}

.logoMenuButton__logo_light path:not(:first-child) {
    fill: #fff;
} */

.menuBox {
    display: contents;
    /* align-items: stretch; */
}

@media screen and (max-width: 600px) {
    .menuBox {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        background-color: #fff;
        box-sizing: border-box;
        height: 100svh;
        padding: 0 3.5vw 19vw 3.5vw;
        width: 85.5vw;
        transition: 300ms transform;
    }

    .menuBox_opened {
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        transform: translateX(0);
    }
}

.menuHeader {
    display: none;
}

@media screen and (max-width: 600px) {
    .menuHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        height: 18.5vw;
        max-height: 100px;
    }

    .menuHeader__closeButton {
        display: initial;
        background: none;
        border: none;
    }
}

.menu {
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: calc(1.5em + 0.15vw);
    margin-left: auto;
    width: 50%;
}

@media screen and (max-width: 600px) {
    .menu {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4.5vw;
        box-sizing: border-box;
        margin-top: auto;
        padding-left: 3vw;
        padding-right: 7vw;
        width: auto;
    }
}

.menu__item {
    display: flex;
    align-items: stretch;
}

.menu__link {
    position: relative;
    display: flex;
    align-items: center;
    color: inherit;
    font-size: 1.125em;
    font-weight: 600;
    padding: 0 0.2vw;
    text-decoration: none;
    transition: 300ms color;
}

@media screen and (max-width: 600px) {
    .menu__link {
        font-size: 1.85em;
        line-height: 1.6em;
        color: var(--main-text-color);
    }
}

.menu__link_active {
    color: #0EC7C1 !important;
}

.menu__link_active::after {
    content: '';
    position: absolute;
    top: 0; left: 50%;
    transform: translateX(-50%);
    background: #0EC7C1;
    border-radius: 2px;
    height: 0.2vw;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .menu__link_active::after {
        background: none;
    }
}

.menu__link_active .menu__loginIcon {
    filter: invert(58%) sepia(61%) saturate(642%) hue-rotate(129deg) brightness(96%) contrast(89%);
}

/* .menu:hover .menu__link:not(:hover),
.menu:hover .menu__button:not(:hover) {
    color: rgba(var(--main-text-color-rgb), 0.7);
}

.menu:hover .menu__button:not(:hover)::after {
    --pointer-color: rgba(var(--main-text-color-rgb), 0.7);
}

.menu:hover .menu__link:not(:hover) .menu__loginIcon {
    filter: opacity(0.6);
}

.menu__link:not(:hover) {
    color: inherit;
} */

.menu__loginIcon {
    height: 1.25vw;
    margin-right: 8px;
    transition: 300ms filter;
}

.navPanel_dark .menu__loginIcon path {
    fill: #fff;
}

.menuHeader__logo {
    height: 10.5vw;
    margin-right: 2vw;
}

.menu__button,
.menuHeader__languageSwitcher {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    margin-left: 2.5vw;
    transition: 300ms color;
    cursor: pointer;
}

.menuHeader__languageSwitcher {
    display: none;
}

@media screen and (max-width: 600px) {
    .menu__button {
        display: none;
    }

    .menuHeader__languageSwitcher {
        display: flex;
        font-size: 1.25em;
    }
}

.menu__button::after,
.menuHeader__languageSwitcher::after {
    --pointer-color: var(--main-text-color);

    content: '';
    display: block;
    background-image:
        linear-gradient(var(--pointer-color) 2px, transparent 2px),
        linear-gradient(90deg, var(--pointer-color) 2px, transparent 2px);
    height: 8px;
    margin-left: 10px;
    transform: rotate(225deg);
    width: 8px;
}

.menu .menu__link_highlighted .menu__button::after {
    --pointer-color: var(--main-text-color);
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
}

@media screen and (max-width: 600px) {
    .actions {
        width: auto;
    }
}

.actions__loginLink {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: 1.125em;
    font-weight: 600;
    margin-left: calc(2em + 0.25vw);
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .actions__loginLink {
        display: none;
    }
}

.actions__loginButton {
    display: none;
}

@media screen and (max-width: 600px) {
    .actions__loginButton {
        display: flex;
        margin-top: 13vw;
        width: 100%;
    }
}
</style>
