import Vue from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-HZN7F270QX' }
});

new Vue({
  render: h => h(App)
}).$mount('#app');
