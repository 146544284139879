<template>
    <div class="successMessage">
        <svg class="successMessage__icon" width="112" height="87" viewBox="0 0 112 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.12" x="18" y="7" width="80" height="80" rx="30" fill="#0EC7C1"/>
            <path d="M35.4071 42.6761C34.3238 42.4688 33.2691 43.1788 33.1489 44.2751C32.8108 47.3592 33.0491 50.4854 33.8579 53.4928C34.8399 57.1443 36.6374 60.5254 39.1152 63.3818C41.5929 66.2381 44.6863 68.4952 48.1625 69.9831C51.6387 71.471 55.4071 72.151 59.1841 71.9719C62.9611 71.7928 66.6482 70.7593 69.968 68.9491C73.2878 67.139 76.1538 64.5993 78.3501 61.5214C80.5465 58.4434 82.0161 54.9074 82.6482 51.1793C83.1688 48.109 83.1102 44.9742 82.4818 41.936C82.2583 40.8559 81.1413 40.2489 80.0825 40.5578C79.0237 40.8667 78.4258 41.9746 78.6327 43.0579C79.1018 45.5127 79.1299 48.0371 78.7103 50.5116C78.1792 53.6441 76.9444 56.6152 75.099 59.2014C73.2535 61.7876 70.8454 63.9215 68.056 65.4425C65.2666 66.9635 62.1685 67.8319 58.9949 67.9824C55.8213 68.1328 52.655 67.5615 49.7341 66.3113C46.8133 65.061 44.2141 63.1646 42.1322 60.7646C40.0504 58.3646 38.54 55.5236 37.7148 52.4555C37.063 50.0317 36.8522 47.5161 37.0868 45.0279C37.1904 43.9298 36.4903 42.8835 35.4071 42.6761Z" fill="#0EC7C1"/>
            <path d="M70.6607 27.7947C71.2677 26.8738 71.0167 25.6275 70.0504 25.0959C67.5647 23.7284 64.8599 22.7916 62.0507 22.3303C58.5835 21.761 55.0352 21.929 51.6372 22.8233C48.2393 23.7175 45.068 25.318 42.3302 27.5204C40.1121 29.3047 38.2188 31.4516 36.7283 33.8656C36.1488 34.804 36.5439 36.0124 37.5256 36.5151C38.5072 37.0178 39.7028 36.6233 40.2964 35.6937C41.5216 33.7752 43.053 32.0648 44.8336 30.6325C47.134 28.782 49.7987 27.4372 52.6538 26.6858C55.5089 25.9344 58.4903 25.7933 61.4036 26.2716C63.6586 26.6419 65.8336 27.3769 67.8445 28.4437C68.8188 28.9606 70.0536 28.7155 70.6607 27.7947Z" fill="#0EC7C1"/>
            <rect x="67.8403" y="37" width="3.61892" height="20.4392" rx="1.80946" transform="rotate(40.0429 67.8403 37)" fill="#0EC7C1"/>
            <rect width="3.61892" height="12.6709" rx="1.80946" transform="matrix(-0.596919 0.802302 0.802302 0.596919 49.3928 44.637)" fill="#0EC7C1"/>
            <circle cx="107.5" cy="1.5" r="1.5" fill="#0EC7C1"/>
            <circle cx="1.5" cy="45.5" r="1.5" fill="#0EC7C1"/>
            <circle cx="110.5" cy="83.5" r="1.5" fill="#0EC7C1"/>
        </svg>
        <h2 class="successMessage__title">{{title}}</h2>
        <p class="successMessage__p">{{message}}</p>
        <a v-if="backURL" :href="backURL" class="button successMessage__button">Thanks</a>
        <a v-else href="https://gyanthub.com/" class="button successMessage__button">Home</a>
    </div>
</template>

<script>
export default {
  name: 'notification-success',
  props: {
    title: String,
    message: String,
    backURL: String
  }
};
</script>

<style scoped>
.successMessage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.successMessage h1 {
    font-size: 1.25em;
    margin-top: 1em;
}

.successMessage__icon {
    margin-bottom: calc(2em + 0.25vw);
    width: calc(6.5em + 0.5vw);
}

.successMessage p {
    color: #878583;
    font-weight: 600;
    line-height: 1.85em;
    margin: 1em 0;
    text-align: center;
}

.successMessage__button {
    margin-top: calc(1em + 0.25vw);
}
</style>
