<template>
    <div class="successMessage">
        <svg class="successMessage__icon" width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4907_2869)">
                <path d="M20.2424 3.78556C20.8369 2.73916 22.3449 2.73916 22.9395 3.78556L39.2 32.4042C39.7875 33.4382 39.0407 34.7214 37.8515 34.7214H5.33033C4.1411 34.7214 3.39429 33.4382 3.98177 32.4042L20.2424 3.78556Z" stroke="#EF0A41" stroke-width="3.10204"/>
                <rect x="20.0487" y="13.0073" width="3.08442" height="10.8571" rx="1.54221" fill="#EF0A41"/>
                <rect x="20.0487" y="26.1909" width="3.08442" height="3.10204" rx="1.54221" fill="#EF0A41"/>
            </g>
            <defs>
                <clipPath id="clip0_4907_2869">
                    <rect width="43" height="38" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        <p class="successMessage__p">{{message}}</p>
    </div>
</template>

<script>
export default {
  name: 'notification-error',
  props: {
    message: String
  }
};
</script>

<style scoped>
.successMessage {
    position: sticky;
    top: clamp(80px, 6.25vw, 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F9E3E8;
    box-sizing: border-box;
    padding: 1em 20%;
    z-index: 1;
}

.successMessage__icon {
    flex-shrink: 0;
    height: calc(2.15em + 0.5vw);
    margin-right: calc(1.25em + 0.25vw);
}

.successMessage p {
    color: #EF0A41;
    font-weight: 600;
    line-height: 1.85em;
    text-align: center;
}
</style>
