<template>
    <footer class="footer">
        <span>© 2024 Gyant. All Rights Reserved.</span>
        <div>
            <a href="https://gyanthub.com/regulations/terms-and-conditions/" class="link">Terms and Conditions</a>
            <a href="https://gyanthub.com/regulations/privacy-policy-2/" class="link">Privacy Policy</a>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'footer-panel'
};
</script>

<style scoped>
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9B9B9B;
    height: calc(8em + 1vw);
    margin-top: auto;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .footer {
        padding: 8.5vw 0 12vw;
    }
}

.footer .link {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    font-weight: 400;
    margin-top: calc(0.25em + 0.25vw);
    text-decoration: none;
    transition: text-shadow 300ms;
}

.link:hover {
    text-shadow: 0.5px 0 0 var(--main-text-color);
}

.footer .link:not(:last-child)::after {
    content: '';
    display: block;
    background: #9B9B9B;
    height: 0.75em;
    margin: 0 0.5em;
    width: 1px;
}

.footer .link:hover {
    color: var(--main-text-color);
}
</style>
