<template>
  <div id="app">
    <nav-bar :parentPage="parentPage"></nav-bar>

    <notification-error v-if="error" :message="error"></notification-error>

    <form v-if="!submitted && smartForm && smartForm.form_elements  && smartForm.grid_columns_ratio"
      class="form"
      @submit.prevent="validate">
      <a v-if="backURL" class="backButton" :href="backURL">
        <svg class="backButton__icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.6298 9.92169L18.018 19.9997L25.6298 30.0788C26.4999 32.4998 23.9999 32.9998 22.4458 31.3096L14.3691 20.6152C14.099 20.2564 14.099 19.7421 14.3691 19.3834L22.4458 8.68891C23.4999 6.99981 26.4999 7.99976 25.6298 9.92169Z" fill="#232323"/>
        </svg>
      </a>
      <smart-form
        :form_elements="smartForm.form_elements"
        :grid_columns_ratio="smartForm.grid_columns_ratio"
        :config="{
          zipCodeDecoder: `${this.apiBaseURL}/settlement/postal_codes/US/`
        }">
      </smart-form>

      <vue-recaptcha
        recaptchaHost="www.recaptcha.net"
        ref="recaptcha"
        size="invisible"
        :sitekey="recaptchaKey"
        @verify="submitSmartForm"
        @expired="onCaptchaExpired"
      />

      <button type="submit" :disabled="submitting"
        class="button form__button">
        {{submitting ? 'Submitting...' : 'Submit'}}
      </button>
    </form>

    <div v-if="loading">Loading...</div>

    <notification-success v-if="submitted"
      :title="smartForm.success_message_title || 'Success!'"
      :message="smartForm.success_message_body || 'Your form has been submitted successfully.'"
      :backURL="backURL">
    </notification-success>

    <scroll-up-button></scroll-up-button>

    <footer-panel></footer-panel>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import navBar from './components/navbar.vue';
import footerPanel from './components/footer.vue';
import notificationSuccess from './components/notification-success.vue';
import notificationError from './components/notification-error.vue';
import scrollUpButton from './components/scroll-up-button.vue';
import { smartForm, validateForm } from '@gyant/smart-form-rendering';
import '@gyant/smart-form-rendering/dist/g-smart-form-renderer.css';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
// const HOME_URL = process.env.HOME_URL;
const HOME_URL = 'https://gyanthub.com/';
const RECAPTCHA_KEY = process.env.VUE_APP_RECAPTCHA_KEY;

export default {
  name: 'app-root',
  components: {
    smartForm,
    VueRecaptcha,
    navBar,
    footerPanel,
    notificationSuccess,
    notificationError,
    scrollUpButton
  },
  data () {
    return {
      recaptchaKey: RECAPTCHA_KEY,
      // recaptchaValidated: false,
      smartForm: {},
      isFormValid: true,
      canGoBack: false,
      backURL: null,
      parentPage: null,
      apiBaseURL: API_BASE_URL,

      loading: false,
      submitting: false,
      submitted: false,
      error: null
    };
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search);
    const formId = urlParams.get('formId');
    const backPath = urlParams.get('backPath');
    if (backPath) {
      this.backURL = `${HOME_URL}${backPath}`;
      this.parentPage = backPath.split('/')[0];
    };

    this.smartForm = this.fetchData(formId);

    window.addEventListener('popstate', this.updateCanGoBack);
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.updateCanGoBack);
  },
  methods: {
    fetchData (formId) {
      this.loading = true;
      fetch(`${API_BASE_URL}/forms/${formId}`)
        .then(response => {
          if (!response.ok) {
            this.error = 'Network response was not ok. Try to refresh the page.';
            this.loading = false;
            throw new Error('Network response was not ok');
          }

          return response.json();
        })
        .then(data => {
          this.smartForm = data;

          this.smartForm.form_elements.forEach(row => {
            row.forEach(cell => {
              if (cell.content.type === 'placeholder') {
                fetch(`${API_BASE_URL}/forms/snippets/${cell.content.props.snippetsId}`)
                  .then(placeholderResponse => {
                    return placeholderResponse.json();
                  })
                  .then(placeholderResponse => {
                    cell.content.props = { ...cell.content.props, formSnippets: placeholderResponse.snippets };
                  })
                  .catch(error => {
                    this.error = error;
                    console.error('There was a problem with snippets fetch operation:', error);
                  });
              }
            });
          });

          this.loading = false;
        })
        .catch(error => {
          this.error = error;
          console.error('There was a problem with form fetch operation:', error);
        });
    },

    submitSmartForm () {
      // this.recaptchaValidated = true;
      this.error = null;

      if (this.isFormValid) {
        this.submitting = true;

        fetch(`${API_BASE_URL}/forms/${this.smartForm.iid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.smartForm)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            this.submitting = false;
            return response.json();
          })
          .then(data => {
            this.submitted = true;
            this.isFormValid = true;
            console.log('Form submitted successfully:', data);
          })
          .catch(error => {
            this.error = error;
            this.submitting = false;
            console.error('There was a problem with form submit operation:', error);
          });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    },

    validate () {
      this.isFormValid = true;
      const { isFormValid, formElements } = validateForm(this.smartForm.form_elements);
      this.isFormValid = isFormValid;
      this.smartForm.form_elements = [...formElements];

      if (this.isFormValid) {
        this.$refs.recaptcha.execute();
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset();
    }
  }
};
</script>

<style>
@import './assets/styles.css';
@import './assets/input-field.css';
@import './assets/select.css';
@import './assets/checkbox.css';
@import './assets/radio-group.css';
@import './assets/multiselect.css';
@import './assets/text.css';
@import './assets/button.css';
@import './assets/loader.css';

#app {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: clamp(80px, 6.25vw, 100px);
  width: 100%;
}

.backButton {
    position: absolute;
    top: 2.15em;
    left: 0;
    transform: translate(calc(-100% - 1vw), calc(-50% + .25em));
    background: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .backButton {
        transform: translate(-2vw, calc(-50% + 0.25em)) scale(1.25);
    }
}

.backButton__icon {
    width: 3vw;
}

@media screen and (max-width: 600px) {
    .backButton__icon {
      width: 100%;
    }
}
</style>
